import React from "react"
import JotformEmbed from 'react-jotform-embed'

import MainContainer from "../components/containers/MainContainer"
import HeadData from "../data/HeadData"

const RequestServicesPage = () => (
  <MainContainer>
    <HeadData 
      title='Request Commercial Laundry Service' 
      noIndex
    />

    <div className='w-full bg-ff-blue-2 pt-12 pb-12 md:pt-24'>
      <h1 className="text-ff-blue-9 text-5xl md:text-6xl text-center font-bold uppercase leading-tight mb-4">Request Commercial Laundry Services</h1>

      <a href='tel:253-507-4633' className="block text-ff-blue-8 text-3xl md:text-4xl text-center font-bold hover:underline">
        253-507-4633
      </a>

      <JotformEmbed src="https://form.jotform.com/250564057707055" />
    </div>
  </MainContainer>
)

export default RequestServicesPage